<template>
    <div class="ststem-log">
        <div class="ststem-log-filter-bar">
            <el-date-picker class="ststem-log-filter-bar-date-range-picker" v-model="selectedDates" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']" clearable :append-to-body="false">
            </el-date-picker>
        </div>
        <div class="ststem-log-table">
            <el-table :data="dataArray" border tooltip-effect="light myTooltips">
                <el-table-column prop="createTimeStr" label="时间" min-width="200/1318%">
                </el-table-column>
                <el-table-column prop="typeName" label="类型" min-width="133/1318%">
                </el-table-column>
                <el-table-column prop="userName" label="使用人" min-width="135/1318%">
                </el-table-column>
                <el-table-column prop="cureMode" label="模式" min-width="150/1318%">
                </el-table-column>
                <el-table-column prop="startTimeStr" label="开始时间" min-width="200/1318%">
                </el-table-column>
                <el-table-column prop="endTimeStr" label="结束时间" min-width="200/1318%">
                </el-table-column>
                <el-table-column prop="durationStr" label="时长" min-width="150/1318%">
                </el-table-column>
                <el-table-column label="操作" min-width="200/1318%">
                    <template slot-scope="scope">
                        <img class="ststem-log-table-check-icon" :src="checkIcon">
                        <button class="ststem-log-table-check-button" @click="onCheckRecord(scope.row)">查看记录</button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class=" ststem-log-pagination">
            <pagination v-if="totalPage != 0" class="device-list-pagination" :total="total" :current-page="currentPage"
                @pagechange="onPageChange"></pagination>
        </div>
    </div>
</template>

<script>
import { DatePicker, Table, TableColumn } from "element-ui";
import Pagination from "@c/common/paging-list/pagination.vue";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        elDatePicker: DatePicker,
        elTable: Table,
        elTableColumn: TableColumn,
        Pagination
    },

    data() {
        return {
            checkIcon: require("@imgs/log-check.png"),
            dataArray: [],
            //filter
            selectedDates: undefined,
            //pagination
            currentPage: undefined,
            totalPage: undefined,
            total: undefined,
        }
    },

    computed: {
        hospitalId() {
            return this.$store.state.organizationModel?.id;
        }
    },

    mounted() {
        this.onSelectedOneMonth() //初始筛选1个月
    },

    watch: {
        hospitalId() {
            this.currentPage = 1
            this.getLogList()
        },

        selectedDates() {
            this.currentPage = 1
            this.getLogList()
        },
    },

    methods: {
        onSelectedOneMonth() {
            //startDate
            var startDate = new Date()
            startDate.setDate(startDate.getDate() - 30)
            startDate.setHours(0)
            startDate.setMinutes(0)
            startDate.setSeconds(0)
            //endDate
            let endDate = new Date()
            endDate.setHours(23)
            endDate.setMinutes(59)
            endDate.setSeconds(59)
            this.selectedDates = [startDate, endDate]
        },

        onPageChange(newPage) {
            this.currentPage = newPage
            this.getLogList()
        },

        onCheckRecord(item) {
            const rawData = item.rawData
            //跳转报告详情
            let routeData = this.$router.resolve({
                path: "/report-frame",
                query: {
                    uid: rawData.uid,
                    singleReportID: rawData.reportId,
                    hospitalId: rawData.hospitalId
                },
            });
            window.open(routeData.href, "_blank");
        },

        async getLogList() {
            try {
                const startTimestamp = this.selectedDates ? Date.parse(this.selectedDates[0]) / 1000 : 0
                const endTimestamp = this.selectedDates ? Date.parse(this.selectedDates[1]) / 1000 : 0
                const params = {
                    "hospitalId": this.hospitalId ?? null,
                    "pageNum": this.currentPage,
                    "pageSize": 10,
                    "start": startTimestamp,
                    "end": endTimestamp,
                }
                const data = await this.$api.getSystemLogList(params);
                this.dataArray = this.composeDataArray(data.list)
                this.currentPage = data.pageNum
                this.totalPage = data.pages
                this.total = data.total

            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        composeDataArray(rawDataArray) {
            return rawDataArray.map((item) => {
                return {
                    rawData: item,
                    createTimeStr: placeholderText(item.createTime),
                    typeName: placeholderText(item.typename),
                    userName: placeholderText(item.userName),
                    cureMode: placeholderText(item.cureMode),
                    startTimeStr: placeholderText(item.startTime),
                    endTimeStr: placeholderText(item.endTime),
                    durationStr: placeholderText(DateTool.formatSeconds(item.longTime))
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.ststem-log {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    padding: 84px 40px 20px 40px;

    &-filter-bar {
        position: absolute;
        top: 0px;
        right: 40px;
        height: 84px;
        display: flex;
        align-items: center;

        &-user-agent {
            margin-right: 15px;
        }

        &-date-range-picker {
            margin-right: 280px;
        }

    }

    &-table {
        /deep/.el-table--border {
            border: 1px solid #E7E7E7 !important;
        }

        //header-cell
        /deep/.el-table th.el-table__cell>.cell {
            color: #909090;
            font-size: 14px;
            font-weight: normal;
            display: flex;
            align-items: center;
            margin-left: 14px;
        }


        //cell
        /deep/ .el-table td.el-table__cell div {
            color: rgba($color: #000000, $alpha: 0.9);
            font-size: 14px;
            margin-left: 14px;
        }

        &-check {
            // height: 100%;
            display: flex;
            align-items: center;

            &-icon {
                width: 17px;
                height: 13px;
            }

            &-button {
                color: #1677FF;
                font-size: 14px;
                margin-left: 4px;
            }
        }

    }

    &-pagination {
        position: absolute;
        right: 40px;
        width: 100%;
        margin-top: 52px;
        display: flex;
        justify-content: flex-end;
    }

}
</style>
